import React from "react";
import theme from "theme";
import { Theme, Text, Button, Box, Section, Image, Strong, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Railways Adventur
			</title>
			<meta name={"description"} content={"Master the Art of Train Management"} />
			<meta property={"og:title"} content={"Railways Adventur"} />
			<meta property={"og:description"} content={"Master the Art of Train Management"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/images.png?v=2024-11-01T12:14:09.112Z"} />
		</Helmet>
		<Components.Header1>
			<Override slot="text" />
			<Override slot="link3" />
			<Override slot="box" />
			<Override slot="link2" />
			<Override slot="box1" />
			<Override slot="link" />
			<Override slot="link1" />
		</Components.Header1>
		<Section
			padding="40px 0 40px 0"
			min-height="100vh"
			lg-padding="80px 0 80px 0"
			md-padding="96px 0 96px 0"
			sm-padding="72px 0 96px 0"
			background="linear-gradient(0deg,rgba(9, 13, 41, 0.33) 0%,rgba(9, 13, 41, 0.58) 50.5%,--color-darkL2 98.1%),url(https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-3.webp?v=2024-11-01T11:48:27.044Z) center/cover"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="100%"
				flex-direction="column"
				justify-content="center"
				align-items="center"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 40px 0px"
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--light"
					font="--headline1"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					text-align="center"
					width="80%"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					sm-width="100%"
				>
					Railways Adventure:{" "}
					<br />
					Master the Art of Train Management
				</Text>
				<Text
					margin="0px 0px 32px 0px"
					color="#FFFFFF"
					font="--lead"
					lg-text-align="center"
					text-align="center"
				>
					Navigate complex intersections, optimize routes, and keep the trains running smoothly!
				</Text>
				<Button
					margin="1rem 0px 0 0px"
					padding="12px 28px 12px 28px"
					background="--color-secondary"
					type="link"
					href="https://play.google.com/store/apps/details?id=com.infinity.railways&hl=en"
					text-decoration-line="initial"
					text-transform="uppercase"
				>
					Start Your Journey
				</Button>
			</Box>
		</Section>
		<Section
			padding="100px 0 120px 0"
			background="--color-darkL2"
			color="#000000"
			sm-padding="80px 0 90px 0"
			quarkly-title="Statistics-5"
			align-items="center"
			justify-content="center"
			align-content="center"
			text-align="center"
		>
			<Override slot="SectionContent" align-content="center" justify-content="center" align-items="center" />
			<Text margin="0px 0px 10px 0px" color="--secondary" font="--lead">
				Our Mission
			</Text>
			<Text margin="0px 0px 0px 0px" color="--light" font="--headline2">
				Our Goal: Lead in Railway Management Games
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="flex"
				margin="50px 0px 0px 0px"
				lg-flex-direction="column"
			>
				<Text
					margin="0px auto 0px auto"
					color="#c0cad8"
					font="normal 300 18px/1.5 --fontFamily-sans"
					padding="0px 25px 0px 0px"
					width="50%"
					lg-width="100%"
					lg-padding="0px 0 30px 0px"
				>
					Our mission is to deliver a premier train management game, creating immersive challenges where players can strategize to keep trains on time. Join us in the adventure of managing real-life scenarios with thoughtful planning and creativity!
				</Text>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" id="gallery">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				align-items="center"
				flex-direction="column"
				justify-content="center"
				margin="0px 0px 64px 0px"
				width="100%"
				sm-margin="0px 0px 30px 0px"
				padding="0px 200px 0px 200px"
				lg-padding="0px 0px 0px 0px"
			>
				<Text margin="0px 0px 16px 0px" font="--headline2" text-align="center" sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif">
					Explore the Features of Railways
				</Text>
				<Text margin="0px 0px 0px 0px" color="--darkL2" text-align="center" font="--lead">
					Optimize train traffic, prevent collisions, and expand your railway empire.
				</Text>
			</Box>
			<Box
				display="grid"
				flex-wrap="wrap"
				width="100%"
				align-items="stretch"
				justify-content="center"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="30px"
				md-grid-template-columns="1fr"
				align-content="stretch"
			>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="center"
				>
					<Image
						src="https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11:48:27.041Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						width="100%"
						height="278px"
						object-fit="cover"
						srcSet="https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-4.webp?v=2024-11-01T11%3A48%3A27.041Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--lead" text-transform="uppercase">
							<Strong>
								Challenging Routes
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Strategically lay out routes and avoid collisions as you expand your network.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="space-between"
					position="relative"
				>
					<Image
						src="https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11:48:27.039Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						object-position="bottom"
						srcSet="https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-6.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--lead" text-transform="uppercase">
							<Strong>
								Smart Traffic
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Unlock special tools that help keep traffic flowing smoothly.
						</Text>
					</Box>
				</Box>
				<Box
					width="100%"
					display="flex"
					padding="7px 7px 40px 7px"
					border-radius="24px"
					align-items="center"
					flex-direction="column"
					border-width="1px"
					border-style="solid"
					border-color="--color-lightD2"
					justify-content="space-between"
				>
					<Image
						src="https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11:48:27.039Z"
						border-radius="16px"
						margin="0px 0px 32px 0px"
						max-width="100%"
						md-width="100%"
						max-height="278px"
						object-fit="cover"
						width="100%"
						srcSet="https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-7.jpg?v=2024-11-01T11%3A48%3A27.039Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						display="flex"
						flex-direction="column"
						padding="0px 30px 0px 30px"
						align-items="flex-start"
						justify-content="flex-start"
					>
						<Text margin="0px 0px 8px 0px" font="--lead" text-transform="uppercase">
							<Strong>
								Endless Exploration
							</Strong>
						</Text>
						<Text margin="0px 0px 48px 0px" color="--darkL2" font="--base">
							Explore new landscapes and expand your railway network with every play.
						</Text>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section
			padding="90px 0 90px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			display="flex"
			background="--color-darkL2"
			overflow-y="hidden"
			overflow-x="hidden"
			quarkly-title="Advantages/Features-12"
			id="features"
		>
			<Override
				slot="SectionContent"
				flex-direction="row"
				flex-wrap="wrap"
				grid-gap="5%"
				sm-min-width="280px"
			/>
			<Box
				display="flex"
				width="50%"
				justify-content="flex-end"
				lg-justify-content="center"
				lg-width="100%"
				padding="0px 0px 0px 16px"
				align-items="center"
				lg-padding="0px 0px 0px 0px"
				font="20px sans-serif"
			>
				<Box
					width="972px"
					height="589px"
					md-display="flex"
					md-justify-content="center"
					box-shadow="--xl"
					lg-height="auto"
				>
					<Image
						height="589px"
						lg-width="100%"
						width="972px"
						border-radius="8px"
						z-index="9"
						position="relative"
						lg-margin="0px 0px 0px 0px"
						box-shadow="--xxl"
						src="https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11:48:27.049Z"
						object-fit="cover"
						lg-height="auto"
						lg-display="block"
						srcSet="https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-5.jpg?v=2024-11-01T11%3A48%3A27.049Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
			</Box>
			<Box
				display="flex"
				width="45%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="flex-start"
				lg-margin="0px 0px 20px 0px"
				sm-margin="32px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
				padding="24px 0px 16px 0px"
				lg-flex-direction="column"
				lg-flex-wrap="no-wrap"
				sm-width="100%"
				md-margin="0px 0px 30px 0px"
				lg-padding="36px 0px 16px 0px"
			>
				<Text
					as="p"
					font="--lead"
					color="--secondary"
					margin="0px 0px 8px 0px"
					border-color="--color-secondary"
				>
					Engage in the Challenge
				</Text>
				<Text
					as="h1"
					margin="0px"
					font="normal 600 40px/1.5 --fontFamily-sans"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 32px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="normal 600 40px/1.2 --fontFamily-sans"
					lg-max-width="720px"
				>
					Dive into the Railways World
				</Text>
				<Text
					as="p"
					margin="20px 0 0 0"
					font="--lead"
					font-size="20px"
					font-weight="300"
					color="--light"
					width="100%"
					md-width="100%"
					md-margin="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					sm-font="--base"
					lg-max-width="720px"
				>
					Join the Railways adventure and experience the thrill of managing train routes, avoiding collisions, and creating a smooth railway network for all passengers.
				</Text>
			</Box>
		</Section>
		<Section
			padding="70px 0 70px 0"
			background="linear-gradient(0deg,rgba(246, 237, 237, 0.86) 0%,rgba(230, 212, 212, 0.87) 100%) scroll,url(https://uploads.quarkly.io/6724b04a41a0cf0024a526df/images/game-8.jpg?v=2024-11-01T11:48:27.045Z) 50% 50%/cover no-repeat scroll"
			lg-background="#fff"
			md-padding="96px 0 70px 0"
			sm-padding="72px 0 70px 0"
			height="auto"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="70%"
				flex-direction="column"
				justify-content="center"
				align-items="flex-start"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 60px 0px"
				sm-margin="0px 0px 40px 0px"
				sm-padding="0px 0px 0px 0px"
			>
				<Text
					margin="0px 0px 24px 0px"
					color="--dark"
					font="--headline2"
					lg-text-align="center"
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					<Strong>
						Embark on Your Railways Journey
					</Strong>
				</Text>
				<Text margin="0px 0px 40px 0px" color="--darkL2" font="--lead" lg-text-align="center">
					Join the world of "Railways" where every decision counts! Master the art of train route management, carefully planning intersections, optimizing traffic, and ensuring smooth, uninterrupted journeys for passengers. Whether you're solving complex challenges or expanding your network, each level offers new opportunities to showcase your strategic skills.
					<br />
					<br />
				</Text>
				<Box display="flex" sm-flex-direction="column" sm-text-align="center" margin="0px aut 0px auto">
					<Link
						href="https://play.google.com/store/apps/details?id=com.infinity.railways&hl=en"
						padding="12px 24px 12px 24px"
						color="--dark"
						background="--color-secondary"
						text-decoration-line="initial"
						font="--lead"
						border-radius="8px"
						margin="0px 16px 0px 0px"
						sm-margin="0px 0px 16px 0px"
						sm-text-align="center"
						hover-background="--color-orange"
						hover-transition="background-color 0.2s ease-in-out 0s"
						transition="background-color 0.2s ease-in-out 0s"
						flex-direction="row"
					>
						Get Started
					</Link>
				</Box>
			</Box>
			<Box
				display="flex"
				width="50%"
				justify-content="center"
				overflow-y="hidden"
				overflow-x="hidden"
				lg-width="100%"
			/>
		</Section>
		<Components.Footer2>
			<Override slot="box" />
			<Override slot="link" />
			<Override slot="link3" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="text3" />
		</Components.Footer2>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"66fbc4301b94b10024315de0"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n  scroll-behavior: smooth;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});